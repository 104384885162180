<template>
	<router-view />
</template>

<script>

export default {
	name: "App"
};
</script>

<style>
@import "~mdb-ui-kit/css/mdb.min.css";
#app {
	font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	/* margin-top: 60px; */
}
</style>
