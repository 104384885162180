import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";
import HomePage from "../components/HomePage.vue";
import ImageIndex from "../components/ImageIndex.vue";
import Avogado6Page from "../components/Avogado6Page.vue";
import RedBookPage from "../components/RedBookPage.vue";
import TestIndex from "../components/TestIndex.vue";

const routes = [
	{
		path: "/:catchAll(.*)",
		component: HomePage,
	},
	{
		path: '/',
		name: 'HomePage',
		component: HomePage,
		// meta: {
		// 	title: ''
		// },
		children: [
			{
				path: '',
				component: ImageIndex,
				meta: {
					title: 'raw',
				}
			},
			{
				path: 'image',
				component: ImageIndex,
				meta: {
					title: 'raw',
				}
			},
			{
				path: 'avogado6',
				component: Avogado6Page,
			},
			{
				path: 'redbook',
				component: RedBookPage,
			},
			{
				path: 'test',
				component: TestIndex,
			},
		]
	}

]

const router = createRouter({
	history: createWebHashHistory(),	//createWebHistory 模式会导致直接打开url会404 需要改nginx,
	routes,
})

export default router