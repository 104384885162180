<template>
	<div class="row my-4 mx-0">
		<p class="note note-primary">
			<strong>说明:</strong> 本页面不定期更新插画师 avogado6 的作品，仅做搬运。所有作品版权属于插画师 avogado6 (avogado6.com)
		</p>
	</div>
	<div class="row">
		<!-- <div class="input-group">
			<div class="form-outline">
				<input
					class="my-form-control"
					v-model="queryData.searchKey"
					@keyup.enter="searchImage"
				/>
			</div>
			<button
				type="button"
				class="btn btn-primary"
				@click="searchImage"
			>
				<i class="fas fa-search"></i>
			</button>
		</div> -->
	</div>
	<div class="row row-cols-1 row-cols-md-6 g-4">
		<div class="col" v-for="(item, index) in images" :key="item.id" :item="item">
			<div class="card h-100">
				<div class="zoomImage" :style="'background-image:url(' + item.url + ')'"
					@click="clickImage(index, item)"></div>
				<div class="card-body">
					<div class="card-title" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
						{{ item.title }}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row my-4">
		<pagination class="d-flex justify-content-end" :total="queryData.total" :pageSize="queryData.size"
			:showSizeChanger="false" @change="pageChange" />
	</div>
</template>

<script>
	import {
		Pagination
	} from "ant-design-vue";
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				queryData: {
					searchKey: "",
					total: 1,
					page: 1,
					size: 18,
					totalPage: 1,
				},
				images: [],
				imageUrls: [],
			};
		},
		methods: {
			getImages() {
				let s = this.queryData.searchKey;
				let skip = (this.queryData.page - 1) * this.queryData.size;

				var cql = "select count(*),* from Avogado6 ";
				if (s != "") {
					cql += `where userTitle like '%${s}%'`;
				}
				cql += `limit ${skip}, ${this.queryData.size} order by -createdAt`;
				this.$AV.Query.doCloudQuery(cql).then((res) => {
					this.images = [];
					this.imageUrls = [];
					res.results.forEach((img) => {
						this.images.push(img.attributes);
						this.imageUrls.push(img.attributes.url);
					});
					this.queryData.total = res.count;
				});
			},
			clickImage(index, img) {
				this.$viewerApi({
					images: this.imageUrls,
					options: {
						toolbar: true,
						initialViewIndex: index
					},
				});
			},
			pageChange(page) {
				this.queryData.page = page;
				this.getImages();
				window.scrollTo(0, 0);
			},
			searchImage() {
				this.queryData.page = 1;
				// window.scrollTo(0, 0);
				this.getImages();
			},
		},
		// computed: {
		//     isLogin() {
		//         return this.$store.state.UserStore.isLogin;
		//     }
		// },
		created() {
			// this.queryData.eventId = -1;
			// if (this.$route.query.eventId) {
			// 	this.queryData.eventId = this.$route.query.eventId;
			// }

			if(this.images.length == 0) {
				this.getImages();
			}
		}
	};
</script>

<style scoped>
	/* .active-green input[type="text"]:focus:not([readonly]) {
	border-bottom: 1px solid lightgreen;
	box-shadow: 0 1px 0 0 lightgreen;
} */
	.zoomImage {
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	.my-form-control {
		/* pointer-events: none; */
		min-height: auto;
		padding: 0.33em 0.75em;
		border: 1px solid #bdbdbd;
		box-sizing: border-box;
		border-radius: 0.3;
		background: transparent;
		transition: all 0.2s linear;
	}
</style>