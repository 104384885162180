<template>
	<div>
		<viewer :images="images">
			<!-- <img
				v-for="src in images"
				:key="src"
				:src="src"
			> -->
		</viewer> 
		<button
			type="button"
			@click="show"
		>Click to show</button>
	</div>
</template>
<script>

export default {
	data() {
		return {
			images: [
				"https://picsum.photos/200/200",
				"https://picsum.photos/300/200",
				"https://picsum.photos/250/200",
			],
		};
	},
	methods: {
		show() {
			this.$viewerApi({
				images: this.images,
			});
		},
	},
};
</script>